import React from 'react';
import { BackgroundImg } from './BackgroundImg';
import step1Img from './images/1.png';
import step2Img from './images/2.png';
import step3Img from './images/3.png';
import arrow from './images/arrow2.png';

const PlanBlock = () => {
  function scroll(selector: string): void {
    const elemToScroll = document.querySelector(selector);
    if (elemToScroll) {
      elemToScroll.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }
  return (
    <section className="plan-block container">
      <div className="trial-intro">
        <h1 className="title">
          Пробное прошло успешно!
          <br /> Что дальше?
        </h1>
        <BackgroundImg />
        <div className="subtitle">
          Мы рады, что вы решили продолжить обучение в нашей школе! Организация
          комфортного учебного процесса — наша основная задача. Для этого вам
          нужно пройти три простых шага:
        </div>
      </div>
      <div className="start-steps">
        <div className="item">
          <img src={step1Img} alt="Цифра 1" />
          <div>
            <span className="danger">Согласовать</span> расписание
          </div>
        </div>
        <div className="item">
          <img src={step2Img} alt="Цифра 2" />
          <div>
            <span className="primary">Оплатить</span> занятия
          </div>
        </div>
        <div className="item">
          <img src={step3Img} alt="Цифра 3" />
          <div>
            <span className="secondary">Ознакомиться</span> с организационными
            моментами
          </div>
        </div>
      </div>
      <div className="trial-intro">
        <div className="subtitle footnote gray">
          Здесь вы найдете детальную информацию по каждому из этапов.
        </div>
        <button onClick={() => scroll('section.schedule-block')}>
          <img className="scroll-img" src={arrow} alt="" />
        </button>
      </div>
    </section>
  );
};

export default PlanBlock;
