import React from 'react';
import { SEO } from '../../components/SEO';
import PlanBlock from './PlanBlock';
import ScheduleBlock from './ScheduleBlock';
import PaymentBlock from './PaymentBlock';
import OrganizationBlock from './OrganizationBlock';
import GoodbyeBlock from './GoodbyeBlock';
import './styles.scss';

const AfterTrialLesson = () => {
  return (
    <main className="page after-trial-lesson">
      <SEO
        title="О пробном занятии"
        description="Пробное занятие прошло успешно! Что дальше?"
      />
      <PlanBlock />
      <ScheduleBlock />
      <PaymentBlock />
      <OrganizationBlock />
      <GoodbyeBlock />
    </main>
  );
};

export default AfterTrialLesson;
