import React from 'react';
import { BackgroundImg } from './BackgroundImg';
import calendarImg from './images/calendar.png';
import technicalImg from './images/technical.png';
import likeImg from './images/like.png';

const OrganizationBlock = () => {
  return (
    <section className="organization-block container">
      <h2 className="title">Организационные моменты</h2>
      <div className="flex-wrapper">
        <div className="organization">
          <div className="header">
            <div>
              <img src={calendarImg} alt="" />
            </div>
            <h3>Отмена и отработка занятий</h3>
          </div>
          <p className="undertitle">
            В нашей школе действует лояльная система отмены и отработки занятий.
          </p>
          <p className="undertitle">
            Отмена урока более чем за 8 часов до его начала производится по
            запросу родителя в мессенджере в любое время. При заблаговременной
            отмене отработка занятия не требуется. Деньги сохраняются на вашем
            балансе и вы можете просто продолжить обучение по обычному
            расписанию или перенести урок на более удобное для вас время, чтобы
            не делать перерыв.
          </p>
          <p className="undertitle">
            Если вы забыли предупредить за 8 часов до начала урока, за занятие
            будут списаны деньги, то есть пропуск будет считаться оплачиваемым.
            Но! У вас будет возможность бесплатно отработать урок в течение 6
            дней вне основного расписания. Таким образом пропуск будет
            компенсирован. Если вы не отработаете занятие, тогда оплата за него
            сгорит.
          </p>
          <p className="undertitle">
            <b>Важно:</b> назначить отработку можно только в то время, которое
            не пересекается с вашим основным расписанием.
          </p>
        </div>
        <BackgroundImg />
      </div>
      <div className="organization">
        <div className="header mt-4">
          <div>
            <img src={technicalImg} alt="" />
          </div>
          <h3>Технические неполадки</h3>
        </div>
        <p className="undertitle">
          Все занятия проходят в онлайн-формате и качество уроков во многом
          зависит от технических моментов. Поэтому важно заботиться о качестве
          связи, которая как минимум зависит от скорости интернета, Skype,
          работоспособности компьютера.
        </p>
        <p className="undertitle">
          Если у вас возникнут проблемы технического характера, мы будем рады
          помочь вам в диагностике и устранении на столько, на сколько это
          возможно в удаленном формате. Напишите администратору о возникших
          неполадках и мы поможем вам связаться с нашим специалистом технической
          поддержки.
        </p>
        <div className="header mt-4">
          <div>
            <img src={likeImg} alt="" />
          </div>
          <h3>Обратная связь</h3>
        </div>
        <p className="undertitle">
          Мы очень трепетно относимся к нашим ученикам и всегда рады получать
          обратную связь об их успехах! Пожалуйста, не стесняйтесь делиться с
          нами своими впечатлениями о занятиях в процессе обучения. Это очень
          ценно!
        </p>
        <p className="undertitle">
          Даже если в какой-то момент вам что-то не понравится, пожалуйста, без
          лишних раздумий пишите администратору. <br />
          Мы разберемся в ситуации и примем меры для ее урегулирования.
        </p>
        <p className="undertitle">
          Важно понимать, что каждый ребенок уникален и к нему нужен свой
          подход, поэтому в процессе обучения могут быть моменты притирки. Ваша
          оперативная обратная связь позволит своевременно внести корректировки
          в формат занятий и сохранить любовь ребенка к выбранному направлению.
          Ведь так хочется чтобы глаза юного программиста всегда горели как
          после пробного урока, верно?
        </p>
      </div>
    </section>
  );
};

export default OrganizationBlock;
