import React from 'react';
import { BackgroundImg } from './BackgroundImg';

const GoodbyeBlock = () => {
  return (
    <section className="goodbye-block container">
      <p className="header-text">
        Теперь вам известно всё для продолжения обучения в нашей школе.
      </p>
      <BackgroundImg />
      <p className="footnote-text">
        Благодарим за внимательное изучение страницы. <br />
        Желаем успехов! До встречи на занятиях!
      </p>
    </section>
  );
};

export default GoodbyeBlock;
