import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

export const BackgroundImg = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          coverImage: file(
            relativePath: {
              eq: "screens/AfterTrialLesson/GoodbyeBlock/images/background-main.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(
                width: 688
                layout: CONSTRAINED
                placeholder: BLURRED
                blurredOptions: { width: 200 }
              )
            }
          }
        }
      `}
      render={(data) => {
        const imageData = getImage(data.coverImage) as IGatsbyImageData;
        return (
          <div className="background-main-container">
            <GatsbyImage
              className="background-main"
              image={imageData}
              alt="Plant"
              loading="eager"
            />
          </div>
        );
      }}
    />
  );
};
