import React from 'react';
import { BackgroundImg } from './BackgroundImg';
import giftImg from './images/giftbox.png';
import creditImg from './images/credit.png';
import paypalImg from './images/paypal.png';
import ymImg from './images/ym.png';
import bankImg from './images/bank.png';
import atmImg from './images/atm.png';
import chatImg from './images/chat-bubble.png';

const PaymentBlock = () => {
  return (
    <section className="payment-block container">
      <div className="balance">
        <BackgroundImg />
        <div className="balance-info">
          <h2 className="title">Как пополнить баланс?</h2>
          <div className="balance-info-item">
            <p>
              Оплатить занятия вы можете на нашем сайте или выбрав один из
              способов, которые описаны ниже.
            </p>
          </div>
          <div className="balance-info-item">
            <h3>Какие есть ограничения по срокам оплаты?</h3>
            <p>
              Пополнить баланс необходимо до начала ближайшего согласованного
              урока.
            </p>
          </div>
          <div className="balance-info-item">
            <h3>Как можно сэкономить?</h3>
            <p className="undertitle">
              Рекомендуем выбирать пакетную оплату занятий. В этом случае вы
              автоматически получаете скидку. И кстати, чем больше занятий в
              пакете, тем больше скидка.
            </p>
          </div>
          <div className="balance-info-item">
            <h3>
              Какие есть ограничения по срокам использования оплаченных занятий?
            </h3>
            <p>
              Никаких. Абсолютно. Вы можете купить 4 урока и пройти их все за 1
              день если есть свободные окошки в графике тренера. А можете
              растянуть их на год и более. Можно даже передарить уроки другу.
              Как вам угодно. При этом вы можете использовать уроки для
              посещения разных направлений в рамках выбранной длительности и
              формата.
            </p>
          </div>
          <div className="gift">
            <div className="gift-img">
              <img src={giftImg} alt="" />
            </div>
            <div className="gift-text">
              <b className="danger">Важно:</b> при оплате первого пакета занятий
              в течение 24 часов после пробного урока вы получите бонус: одно
              занятие в подарок!
            </div>
          </div>
        </div>
      </div>
      <div className="payment">
        <h3 className="title">Способы оплаты:</h3>
        <div className="item">
          <div className="pay-method">
            <div>
              <img src={creditImg} alt="" />
            </div>
            <h4>Банковская карта</h4>
          </div>
          <p className="pay-description">
            Самый оптимальный вариант — оплата картой у нас на сайте. Для этого
            перейдите по{' '}
            <a
              className="danger"
              href="https://up-school.online/programming-pay/"
              rel="noreferrer"
              target="_blank"
            >
              ссылке
            </a>{' '}
            и выберите пакет занятий в соответствии с предпочтительной формой
            проведения уроков и длительностью.
          </p>
          <p className="pay-description">
            <b>Важно:</b> убедитесь, что формат оплаченных занятий соответствует
            записи.
          </p>
        </div>
        <div className="item">
          <div className="pay-method">
            <div>
              <img src={paypalImg} alt="" />
            </div>
            <h4>PayPal</h4>
          </div>
          <p className="pay-description">
            Если оплата картой вам не подходит, вы можете произвести платеж
            через PayPal (
            <a
              className="danger"
              href="https://paypal.me/itgenik"
              rel="noreferrer"
              target="_blank"
            >
              ссылка
            </a>
            ). В примечании к платежу укажите ФИО ученика, за которого
            производите оплату.
          </p>
        </div>
        <div className="item">
          <div className="pay-method">
            <div>
              <img src={ymImg} alt="" />
            </div>
            <h4>ЮMoney (Яндекс.Деньги)</h4>
          </div>
          <p className="pay-description">
            Доступен также перевод ЮMoney (Яндекс.Деньги): 410019555747139
            <br />В примечании к платежу укажите ФИО ученика, за которого
            производите оплату.
          </p>
        </div>
        <div className="item">
          <div className="pay-method">
            <div>
              <img src={bankImg} alt="" />
            </div>
            <h4>Перевод на расчетный счет в банке</h4>
          </div>
          <p className="pay-description">
            Используйте этот метод в крайнем случае, т.к. банки-посредники часто
            берут высокую комиссию (до 30%) за международные переводы.
          </p>
          <p className="pay-description">
            Индивидуальный предприниматель Шалабанов Юрий Сергеевич
            <br />
            УНП 191201676
            <br />
            г. Минск, пер. Щербакова, д. 6, кв. 5
            <br />
            ТЕЛ./ФАКС 237-82-40
            <br />
            Свидетельство № 191201676, выдано 17.11.2009Г. Минским горисполкомом
            <br />
            Р/С BY75TECN30131240900000000000 в ОАО "Технобанк", г. Минск ул.
            Кропоткина 44, код TECNBY22
            <br />
            валютный счет BY77TECN30131240920110000000 (USD)
            <br />
            валютный счет BY84TECN30131240900000000032 (RUB)
            <br />
            валютный счет BY63TECN30131240900000000022 (EUR)
            <br />в ОАО "Технобанк", г. Минск ул. Кропоткина 44, код TECNBY22
          </p>
          <p className="pay-description">
            В назначении платежа укажите ФИО ученика и вышлите скриншот
            электронного чека, чтобы мы смогли быстрее идентифицировать платёж.
          </p>
        </div>
        <div className="item">
          <div className="pay-method">
            <div>
              <img src={atmImg} alt="" />
            </div>
            <h4>Система Расчет (ЕРИП)</h4>
          </div>
          <p className="pay-description">
            Подходит только для РБ. Можно совершить платеж через кассы банка,
            банкоматы, почтовые отделения, инфокиоски, устройства приема
            наличных денег (cash-in). Наиболее удобно пользоваться системой
            «Расчет» при использовании Интернет-банка.
          </p>
          <p className="pay-description">
            Инструкция при оплате через систему ЕРИП:
          </p>
          <p className="pay-description">
            В ЕРИП выберите произвольный платеж — введите ИП Шалабанов Юрий
            Сергеевич — введите расчетный счет — введите сумму — и укажите за
            что ("за обучение").
          </p>
        </div>
        <div className="item">
          <div className="pay-method">
            <div>
              <img src={chatImg} alt="" />
            </div>
            <p>
              Если возникнут какие-либо вопросы по оплате, напишите
              администратору. Мы постараемся вам помочь.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentBlock;
