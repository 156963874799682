import React from 'react';
import { BackgroundImg } from './BackgroundImg';
import accordion_items from './accordion_items';
import Accordion from 'components/Accordion';
import checkImg from './images/check.png';

const ScheduleBlock = () => {
  return (
    <section className="schedule-block container">
      <div className="schedule">
        <div className="agreement">
          <h2 className="title">Согласование расписания</h2>
          <p className="undertitle">Для согласования расписания укажите:</p>
          <div className="check-item">
            <div>
              <img src={checkImg} alt="" />
            </div>
            <div>
              Удобный <b>формат и продолжительность</b> занятий:
              индивидуально-групповой (120 или 60 мин) или индивидуальный (60
              мин).
            </div>
          </div>
          <div className="check-item">
            <div>
              <img src={checkImg} alt="" />
            </div>
            <div>
              <b>Количество</b> уроков в неделю. Как часто вы хотите заниматься?
            </div>
          </div>
          <div className="check-item">
            <div>
              <img src={checkImg} alt="" />
            </div>
            <div>
              Предпочтительные <b>дни и время</b> для занятий. Чем шире диапазон
              дат и времени, тем скорее мы подберем для вас оптимальный вариант.
            </div>
          </div>
        </div>
        <BackgroundImg />
      </div>
      <div className="accordion">
        <p className="title gray">
          Если какой-то из пунктов вызывает затруднение, ниже даны развернутые
          пояснения.
        </p>
        <Accordion items={accordion_items} />
      </div>
    </section>
  );
};

export default ScheduleBlock;
