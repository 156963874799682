import React from 'react';

type items = {
  title: string | JSX.Element;
  content: string | JSX.Element;
  color?: string;
}[];
const accordionItems: items = [
  {
    title: 'Какую форму проведения занятий выбрать?',
    content: (
      <div>
        <p>
          На текущий момент доступно два варианта: индивидуально-групповой и
          индивидуальный формат.
        </p>
        <p>
          На уроках в <b>индивидуально-групповом формате</b> тренер курирует до
          6 учеников одновременно. При этом учитель объясняет материал,
          подбирает задания, отвечает на вопросы каждому индивидуально. Дети
          между собой никак не взаимодействуют и даже не слышат друг друга.
          Поэтому можете не переживать за то, что ребенок будет отвлекаться на
          других ребят.
        </p>
        <p>
          Данный формат предполагает умеренный уровень самостоятельной работы.
          Благодаря этому помимо прочих полезных навыков развиваются
          аналитические способности, умение задавать правильные вопросы,
          самостоятельно концентрироваться на поставленной задаче и не только. В
          общем, мы с самого начала нацелены на помощь в формировании ценнейших
          навыков для успешной реализации в профессии программист.
        </p>
        <p>
          Доступная продолжительность уроков в индивидуально-групповом формате:
          120 мин и 60 мин.
        </p>
        <p>
          <b>Индивидуальная форма</b> проведения занятий предполагает
          взаимодействие один на один.
        </p>
        <p>Данный формат мы рекомендуем:</p>
        <ul>
          <li>
            Деткам, которым сложно усидеть на месте и им требуется регулярный
            контроль наставника, чтобы возвращать внимание к учебному процессу.
          </li>
          <li>
            Также индивидуальные уроки рекомендуем малышам, у которых есть
            сложности с пользовательскими навыками и/или чтением. Как правило,
            таким ученикам требуется больше поддержки, чтобы освоиться в рабочей
            среде и разобраться в материалах.
          </li>
          <li>
            Ребятам, у которых обучение проходит не на родном языке и в самом
            начале требуется больше помощи с переводом незнакомых терминов.
          </li>
        </ul>
        <p>Доступная продолжительность уроков: 60 мин.</p>
        <p>
          Обращаю ваше внимание, что в процессе обучения формат можно изменить.
          К примеру, если ребенку нужно больше времени на адаптацию, для начала
          вы можете выбрать индивидуальную форму проведения уроков и чуть позже
          перейти на индивидуально-групповую.
        </p>
      </div>
    ),
    color: 'blue',
  },
  {
    title: 'Какой формат расписания вам подходит?',
    content: (
      <div>
        <p>
          В нашей школе вы можете выбрать фиксированный или свободный график.
        </p>
        <p>
          <b>Занятия по фиксированному графику</b> проходят в определенные дни и
          время по заранее согласованному расписанию (например, каждый вторник и
          пятницу в 19:00). При записи время автоматически закрепляется за вами
          на неограниченный период. Благодаря этой функции вам не нужно при
          каждой новой оплате повторно согласовывать расписание, что очень
          удобно и экономит время.
        </p>
        <p>
          <b>Занятия по свободному расписанию.</b> Данный формат предполагает
          отдельное согласование каждого урока с учетом ваших пожеланий и
          свободных окошек в графике тренеров. Такой вариант отлично подходит
          загруженным ребятам, которым сложно выделить определенное время для
          регулярных занятий.
        </p>
        <p>Не знаете что выбрать?</p>
        <p>
          Мы рекомендуем занятия на постоянной основе по фиксированному графику.
          Опираясь на свой опыт, можно смело сказать, что такие уроки
          эффективнее, так как носят системный характер и как правило проходят с
          одним тренером. Однако если ребенок очень загружен, может просто не
          быть возможности для регулярных занятий. Тогда конечно лучше
          остановить свой выбор на свободном графике и заниматься в удовольствие
          по возможности.
        </p>
      </div>
    ),
    color: 'red',
  },
  {
    title: 'Сколько раз в неделю лучше заниматься?',
    content: `Выбор периодичности занятий зависит прежде всего от вашего желания,
      возможностей и поставленных целей. Оптимально — 2 раза в неделю. Однако многие
      наши ученики занимаются по одному разу в неделю из-за высокой школьной нагрузки.
      Очень важно, выбрать комфортный темп обучения, чтобы не перегружать ребенка.`,
    color: 'yellow',
  },
];

export default accordionItems;
